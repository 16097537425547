@use "sass:map";
@use 'sass:color';

@mixin fnl-mdc-select($fnl-palette) {

  .fnl-form-field {

    &.mat-mdc-form-field.mat-form-field-appearance-outline {

      --mat-select-trigger-text-font: var(--fnl-font-family-primary);
      --mat-select-trigger-text-line-height: 20px;
      --mat-select-trigger-text-size: 14px;
      --mat-select-trigger-text-weight: 400;

      --mat-select-placeholder-text-color: #{rgba(map.get($fnl-palette, text), 0.6)};

      &.fnl-form-field--small {
        --mat-select-trigger-text-size: 14px;
      }

      &.fnl-form-field--medium {
        --mat-select-trigger-text-size: 14px;
      }

      &.fnl-form-field--large {
        --mat-select-trigger-text-size: 16px;
      }

      .mat-mdc-select {

        .mat-mdc-select-trigger {
          // this is in order to make the arrow and the text above the outline background
          z-index: 1;
        }

        .mat-mdc-select-arrow-wrapper {
          height: 20px;
          flex-shrink: 0;
          display: inline-flex;
          align-items: center;
          margin-top: -3px;
        }

        .mat-mdc-select-arrow {
          border-left: 0;
          margin: 0 4px;
          width: 6px;
          height: 6px;
          border-top: 2px solid #{map.get($fnl-palette, blue)};
          border-right: 2px solid #{map.get($fnl-palette, blue)};
          transform-origin: center;
          transform: rotate(135deg);
          opacity: 1;

          svg {
            display: none;
          }
        }

        &[aria-expanded="true"] .mat-mdc-select-arrow {
          transform: translateY(3px) rotate(-45deg);
        }

      }

      &.mat-form-field-disabled {

        --mat-select-placeholder-text-color: #{rgba(map.get($fnl-palette, text), 0.38)};

        .mat-mdc-select-arrow {
          opacity: .5;
        }
      }
    }

    &.fnl-form-field--dark-theme {
      &.mat-mdc-form-field.mat-form-field-appearance-outline {
        .mat-mdc-select {
          .mat-mdc-select-arrow {
            border-color: var(--fnl-color-white-tr);
          }
        }
      }
    }
  }

  .fnl-overlay-pane.cdk-overlay-pane {

    --mat-option-label-text-line-height: 20px;
    --mat-option-label-text-font: var(--fnl-font-family-primary);
    --mat-option-label-text-size: 14px;

    --mat-option-selected-state-label-text-color: #{map.get($fnl-palette, text)};
    --mat-option-label-text-color: #{map.get($fnl-palette, text)};

    --mat-select-panel-background-color: #{map.get($fnl-palette, white)};

    --mat-optgroup-label-text-font: var(--fnl-font-family-primary);
    --mat-optgroup-label-text-line-height: 20px;
    --mat-optgroup-label-text-size: 14px;
    --mat-optgroup-label-text-weight: 500;
    --mat-optgroup-label-text-color: var(--fnl-color-text);

    --mat-option-selected-state-layer-color: #{rgba(map.get($fnl-palette, blue), 0.16)};
    --mat-option-focus-state-layer-color: #{rgba(map.get($fnl-palette, black), 0.12)};
    --mat-option-hover-state-layer-color: #{rgba(map.get($fnl-palette, black), 0.08)};

    // dropdown in select
    div.mat-mdc-select-panel {
      padding: 0;
      border-radius: 16px !important;
    }

    .mat-mdc-option {
      min-height: 39px;

      .mdc-list-item__primary-text {
        display: block;
        width: 100%;
      }

      &:not(:last-child) {
        border-bottom: solid 1px var(--fnl-color-fish);
      }

      &:hover {
        --mat-option-selected-state-layer-color: #{rgba(map.get($fnl-palette, blue), 0.2)};
        --mat-option-focus-state-layer-color: #{rgba(map.get($fnl-palette, black), 0.16)};
      }

      &.mat-mdc-option-active.mdc-list-item--selected {
        --mat-option-selected-state-layer-color: #{rgba(map.get($fnl-palette, blue), 0.2)};

        &:hover {
          --mat-option-selected-state-layer-color: #{rgba(map.get($fnl-palette, blue), 0.24)};
        }
      }
    }

    .mat-mdc-optgroup {

      $option-padding: 16px;

      .mat-mdc-optgroup-label {
        padding-left: $option-padding;
        padding-right: $option-padding;
        min-height: 39px;
        background-color: var(--fnl-color-background);
        border-bottom: solid 1px var(--fnl-color-fish);
      }

      &:not(:last-child) {
        .mat-mdc-option {
          min-height: 39px;
          border-bottom: solid 1px var(--fnl-color-fish);
        }
      }

      &:last-child {
        .mat-mdc-option:last-child {
          min-height: 40px;
          border-bottom: none;
        }
      }

      .mat-mdc-option:not(.mat-mdc-option-multiple) {
        padding-left: $option-padding;
        padding-right: $option-padding;
      }
    }

    .fnl-form-field--dark-theme {
      --mat-select-panel-background-color: var(--fnl-color-blue-dark);

      --mat-option-selected-state-label-text-color: var(--fnl-color-white);
      --mat-option-label-text-color: var(--fnl-color-white);

      --mat-optgroup-label-text-color: var(--fnl-color-white);

      --mat-option-selected-state-layer-color: #{rgba(map.get($fnl-palette, blue-light), 0.2)};
      --mat-option-focus-state-layer-color: #{rgba(map.get($fnl-palette, white), 0.12)};
      --mat-option-hover-state-layer-color: #{rgba(map.get($fnl-palette, white), 0.08)};

      scrollbar-color: rgba(52, 102, 138, 0.8) transparent;

      .mat-mdc-option {

        &:not(:last-child) {
          border-bottom-color: var(--fnl-color-blue);
        }

        &:hover {
          --mat-option-selected-state-layer-color: #{rgba(map.get($fnl-palette, blue), 0.3)};
          --mat-option-focus-state-layer-color: #{rgba(map.get($fnl-palette, white), 0.16)};
        }

        &.mat-mdc-option-active.mdc-list-item--selected {
          --mat-option-selected-state-layer-color: #{rgba(map.get($fnl-palette, blue), 0.3)};

          &:hover {
            --mat-option-selected-state-layer-color: #{rgba(map.get($fnl-palette, blue), 0.34)};
          }
        }
      }
    }
  }
}
