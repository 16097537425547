@use 'sass:map';
@use '../../../variables' as vars;


@mixin fnl-mdc-table($fnl-palette) {

  table.fnl-mdc-table,
  .mat-mdc-table.fnl-mdc-table {

    table-layout: fixed;
    border-collapse: collapse;

    thead tr,
    .mat-mdc-header-row {
      height: 40px;

      th,
      .mat-mdc-header-cell {
        @extend .text-th;

        box-sizing: border-box;
        padding: 0 8px;

        vertical-align: middle;

        border-bottom-width: 1px;
        border-bottom-style: solid;

        font-weight: normal;

        color: map.get($fnl-palette, black);
        border-bottom-color: map.get($fnl-palette, tables-border);
        background: map.get($fnl-palette, background);

        &.mat-sort-header {
          padding-right: 16px;

          .mat-sort-header-container {
            position: relative;

            display: inline-flex;

            &::after {
              position: absolute;
              top: 50%;
              right: -17px;

              display: block;

              width: 12px;
              height: 13px;

              content: '';
              transform: translateY(-50%);

              background-color: map.get($fnl-palette, grey);

              mask: url('../../../../../assets/images/icons/arrow-sort-down.svg') no-repeat center center;
            }
          }

          .mat-sort-header-arrow {
            display: none;
          }

          .mat-sort-header-content {
            display: block;

            text-align: inherit;

            color: inherit;
          }

          .mat-sort-header-sorted {
            &::after {
              background-color: map.get($fnl-palette, blue-light);
            }
          }

          &.cdk-program-focused .mat-sort-header-container,
          &.cdk-focused .mat-sort-header-container {
            border-bottom: none;
          }
        }

        &[aria-sort='ascending'],
        &[aria-sort='descending'] {
          color: map.get($fnl-palette, blue-light);
        }

        &[aria-sort='ascending'] {
          .mat-sort-header-container::after {
            transform: translateY(-50%) rotate(180deg);
          }
        }

        &:first-child {
          padding-left: 24px;
        }

        &.fnl-text-cell {
          text-align: left;
          justify-content: start;
        }

        &.fnl-number-cell {
          text-align: right;
          justify-content: end;
        }

        &.fnl-controls-cell {
          text-align: center;
          justify-content: center;
        }

        &:last-child {
          padding-right: 24px;
        }
      }

      &.fnl-filter-row {
        height: auto;

        th,
        .mat-mdc-header-cell {
          background-color: map.get($fnl-palette, white);
          padding: 0;

          &:not(:last-child) {
            border-right: 1px solid map.get($fnl-palette, tables-border);;
          }

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-left: 0;
          }
        }
      }
    }

    tbody tr,
    .mat-mdc-row {

      height: 32px;

      &:hover {
        background-color: rgba(map.get($fnl-palette, background), 0.5);
      }

      td,
      .mat-mdc-cell {
        @extend .text-th;

        box-sizing: border-box;
        padding: 4px 16px 4px 8px;

        vertical-align: middle;

        border-bottom: 1px solid map.get($fnl-palette, fish);
        color: map.get($fnl-palette, text);

        &:first-child {
          padding-left: 24px;
        }

        &.fnl-text-cell {
          text-align: left;
          justify-content: start;
        }

        &.fnl-number-cell {
          text-align: right;
          justify-content: end;
        }

        &.fnl-controls-cell {
          text-align: center;
          justify-content: center;
        }

        &:last-child {
          padding-right: 24px;
        }
      }
    }
  }
}
