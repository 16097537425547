@import '../variables';
@import '../typography';
$letter-spacing-button: 1.25px;
$border-radius-button: 20px;
$height-button: 40px;
$line-height-button: $height-button;
$border-radius-button-small: 16px;
$height-button-small: 32px;
$line-height-button-small: calc($height-button-small - 4px);

.mat-button-base {
  $border-width-stroked: 2px;

  @extend .text-button;

  margin: 0;

  &.mat-raised-button,
  &.mat-stroked-button,
  &.mat-flat-button {
    line-height: $line-height-button;

    height: $height-button;

    letter-spacing: $letter-spacing-button;

    border-radius: $border-radius-button;
  }

  &.mat-raised-button {
    box-shadow: inherit;

    &.mat-primary {
      background: $color-blue;

      &[disabled='true'],
      &.mat-button-disabled {
        background: rgba($color-blue, 0.6);
      }
      &:hover:not([disabled='true']):not(.mat-button-disabled) {
        background: $color-blue-dark;
      }
      &_light {
        background: $color-blue-text;
      }
    }

    &.mat-accent {
      color: $color-white;
      background: $color-green;

      &[disabled='true'],
      &.mat-button-disabled {
        background: rgba($color-green, 0.6);
      }
      &:not([disabled='true']):not(.mat-button-disabled) {
        &:hover,
        &:active,
        &.active {
          background: $color-accent-button-hover;
        }
      }
    }

    &.mat-warn {
      background: $color-orange;

      &[disabled='true'],
      &.mat-button-disabled {
        background: rgba($color-orange, 0.6);
      }
      &:not([disabled='true']):not(.mat-button-disabled) {
        &:hover,
        &:active,
        &.active {
          background: adjust-color($color-orange, $lightness: -10%);
        }
      }
    }
  }
  &.mat-stroked-button {
    line-height: calc(100% - #{$border-width-stroked} * 2);

    transition: 0.2s background-color, 0.2s border-color;

    border-width: $border-width-stroked;

    &.mat-primary {
      color: $color-blue;
      border-color: $color-blue;

      &[disabled='true'],
      &.mat-button-disabled {
        color: rgba($color-blue, 0.6);
        border-color: rgba($color-blue, 0.6);
      }
      &:hover:not([disabled='true']):not(.mat-button-disabled) {
        color: $color-white;
        border-color: $color-blue;
        background-color: $color-blue;
      }
      &_light {
        color: $color-blue-text;
        border-color: $color-blue-text;
      }
    }
    &.mat-accent {
      color: $color-green;
      border-color: $color-green;

      &[disabled='true'],
      &.mat-button-disabled {
        color: rgba($color-green, 0.6);
        border-color: rgba($color-green, 0.6);
      }
      &:hover:not([disabled='true']):not(.mat-button-disabled) {
        color: $color-white;
        border-color: $color-green;
        background-color: $color-green;
      }
    }
  }

  &.mat-button {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.5px;

    &.mat-accent {
      color: $color-green;
    }
  }

  // TODO: create variables
  &.mat-button-small {
    font-size: 12px;
    font-weight: 700;
    line-height: $line-height-button-small;

    min-width: 88px;
    height: $height-button-small;
    padding: 0 9px;

    vertical-align: top;
    letter-spacing: 0.5px;
  }

  &.mat-icon-button.cdk-program-focused .mat-button-focus-overlay {
    opacity: 0;
  }
}
