@import '../variables';

.mat-checkbox-indeterminate,
.mat-checkbox-checked {
  &.mat-primary .mat-checkbox-background {
    background-color: $color-blue-dark;
  }
}

.mat-checkbox-checked:not(.mat-checkbox-disabled),
.mat-checkbox:active:not(.mat-checkbox-disabled) {
  &.mat-primary .mat-ripple-element {
    background: $color-blue-dark;
  }
}
