@use 'sass:map';
@use '../../../variables' as vars;

@mixin fnl-mdc-tooltip($fnl-palette) {
  .fnl-tooltip {

    &.mat-mdc-tooltip .mdc-tooltip__surface
    {
      font-size: 11px;
      text-transform: lowercase;

      color: map.get($fnl-palette, white);
      border-radius: 8px;
      background: map.get($fnl-palette, blue-light);
    }

  }

  .fnl-tooltip-without-transform {

    &.mat-mdc-tooltip .mdc-tooltip__surface
    {
      font-size: 11px;
      text-transform: none;

      color: map.get($fnl-palette, white);
      border-radius: 8px;
      background: map.get($fnl-palette, blue-light);
    }

  }
}
