@use "variables" as vars;

:root {
  --fnl-font-family-primary: #{vars.$font-family-primary};
  --fnl-font-family-secondary: #{vars.$font-family-secondary};
  --fnl-font-size: #{vars.$font-size};

  --fnl-container-x-large-width: #{vars.$container-x-large-width};
  --fnl-container-x-large-indent: #{vars.$container-x-large-indent};

  --fnl-container-width: #{vars.$container-width};
  --fnl-container-indent: #{vars.$container-indent};

  --fnl-container-tablet-width: #{vars.$container-tablet-width};
  --fnl-container-tablet-indent: #{vars.$container-tablet-indent};
  --fnl-container-tablet-portrait-width: #{vars.$container-tablet-portrait-width};

  --fnl-screen-x-large: #{vars.$screen-x-large};
  --fnl-screen-large: #{vars.$screen-large};
  --fnl-screen-tablet: #{vars.$screen-tablet};
  --fnl-screen-tablet-portrait: #{vars.$screen-tablet-portrait};

  --fnl-color-black: #{vars.$color-black};
  --fnl-color-text: #{vars.$color-text};
  --fnl-color-grey: #{vars.$color-grey};
  --fnl-color-white: #{vars.$color-white};
  --fnl-color-white-tr: #{vars.$color-white-tr};
  --fnl-color-fish: #{vars.$color-fish};
  --fnl-color-bg-tab: #{vars.$color-bg-tab};
  --fnl-color-background: #{vars.$color-background};
  --fnl-color-blue-dark: #{vars.$color-blue-dark};
  --fnl-color-blue: #{vars.$color-blue};
  --fnl-color-blue-light: #{vars.$color-blue-light};
  --fnl-color-green: #{vars.$color-green};
  --fnl-color-green-dark: #{vars.$color-green-dark};
  --fnl-color-blue-text: #{vars.$color-blue-text};
  --fnl-color-text-green: #{vars.$color-text-green};
  --fnl-color-text-number: #{vars.$color-text-number};
  --fnl-color-red: #{vars.$color-red};
  --fnl-color-cool-gray1c: #{vars.$color-cool-gray1c};
  --fnl-color-orange: #{vars.$color-orange};
  --fnl-color-yellow: #{vars.$color-yellow};
  --fnl-color-bg-3: #{vars.$color-bg-3};

  --fnl-color-bg-line: #{vars.$color-bg-line};
  --fnl-color-tree-stick: #{vars.$color-tree-stick};
  --fnl-color-special: #{vars.$color-special};
  --fnl-color-button-disabled: #{vars.$color-button-disabled};

  --fnl-color-new-random-color: #{vars.$color-new-random-color};
  --fnl-color-tables-border: #{vars.$color-tables-border};
  --fnl-color-accent-button-hover: #{vars.$color-accent-button-hover};

  --fnl-color-black-tr: #{vars.$color-black-tr};

  --fnl-shadow-block: #{vars.$shadow-block};
  --fnl-shadow-block-filter: #{vars.$shadow-block-filter};
  --fnl-shadow-popup: #{vars.$shadow-popup};
  --fnl-shadow-popup-filter: #{vars.$shadow-popup-filter};

  --fnl-animation: #{vars.$animation};

  --fnl-overlay-z-index: #{vars.$overlay-z-index};
}
