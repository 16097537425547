@import '../variables';

.cdk-global-scrollblock {
  height: auto !important;
}

mat-dialog-container {
  &.mat-dialog-container {
    padding: 16px 24px;

    border-radius: 16px;
    background-color: $color-white;
    box-shadow: $shadow-block;
  }
  .mat-dialog-title {
    margin: 0 0 16px;
  }
  .mat-dialog-content {
    margin: 0 -16px;
    padding: 0 16px;
    overflow-x: hidden;
  }
  .mat-dialog-actions {
    min-height: 40px;
    margin-bottom: -16px;
    padding: 8px 24px 24px 24px;

    justify-content: center;

    .mat-button-base {
      min-width: 184px;
      & + .mat-button-base {
        margin-left: 8px;
      }
    }
  }
}
