@import 'variables';

@mixin non-button-mixin(
  $with-padding: true,
  $with-border: true,
  $default-border-radius: 0,
  $default-background: transparent
) {
  -webkit-border-radius: $default-border-radius;
  border-radius: $default-border-radius;
  background-color: $default-background;

  -webkit-appearance: none;
  @if $with-padding {
    padding: 0;
  }

  @if $with-border {
    border-width: 0;
  }
}

.link {
  cursor: pointer;

  color: $color-blue;

  &:hover {
    color: $color-blue;
  }

  &:active {
    color: $color-blue-dark;
  }
}

.link-underlined {
  @extend .link;

  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }
}

.link-colorized {
  @extend .link;

  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    text-decoration: underline;
  }
}

.text-h1 {
  font-family: $font-family-secondary;
  font-size: 27px;
  font-weight: bold;
  line-height: 32px;

  margin: 0;

  letter-spacing: 0.2px;
}

.text-h2 {
  font-family: $font-family-secondary;
  font-size: 22px;
  font-weight: bold;
  line-height: 24px;

  margin: 0;

  letter-spacing: 0.15px;
}

.text-h3 {
  font-family: $font-family-secondary;
  font-size: 19px;
  font-weight: bold;
  line-height: 24px;

  margin: 0;

  letter-spacing: 0.15px;
}

.text-h4 {
  font-family: $font-family-secondary;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;

  margin: 0;

  letter-spacing: 0.15px;
}

.text-h5 {
  font-family: $font-family-secondary;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;

  margin: 0;

  letter-spacing: 0.15px;
}

.text-h6 {
  font-family: $font-family-secondary;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  margin: 0;

  letter-spacing: 0.15px;
}

.text-body1 {
  font-size: 14px;
  line-height: 20px;
}

.text-body1-md {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.text-body2 {
  font-size: 13px;
  line-height: 16px;

  letter-spacing: 0;
}

.text-th {
  font-size: 13px;
  line-height: 15px;
}

.text-td {
  font-size: 13px;
  line-height: 32px;
}

.text-nav {
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;

  letter-spacing: -0.2px;
  text-transform: uppercase;
}

.text-tab {
  font-size: 15px;
  font-weight: 500;
  line-height: 40px;

  letter-spacing: 0.15px;
}

.text-subtitle1 {
  font-family: $font-family-primary;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}

.text-subtitle2 {
  font-size: 16px;
  line-height: 20px;
}

.text-subtitle3 {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}

.text-button {
  font-size: 14px;
  font-weight: bold;
  line-height: 40px;

  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.text-button-small {
  font-size: 12px;
  font-weight: bold;
  line-height: 32px;

  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.text-caption {
  font-size: 12px;
  line-height: 14px;
}

.text-overline {
  font-size: 10px;
  line-height: 16px;

  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.non-button {
  @include non-button-mixin;
}

.base-info {
  @extend .text-body1;

  box-sizing: border-box;
  width: 204px;
  padding: 16px 20px;
}

.with-word-break {
  word-break: normal;

  overflow-wrap: anywhere;

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      word-break: break-word;

      overflow-wrap: normal;
    }
  }
}
