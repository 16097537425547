@use 'sass:map';
@use '../../../variables' as vars;

@mixin fnl-mdc-tab-group($fnl-palette) {

  .fnl-mdc-tab-group {
    --mat-tab-header-active-label-text-color: #{map.get($fnl-palette, blue-light)};
    --mat-tab-header-inactive-label-text-color: #{map.get($fnl-palette, blue-dark)};
    --mdc-tab-indicator-active-indicator-color: #{map.get($fnl-palette, blue-light)};
    --mat-tab-header-active-ripple-color: #{map.get($fnl-palette, blue-light)};
    --mat-tab-header-inactive-ripple-color: #{map.get($fnl-palette, blue-light)};
    --mat-tab-header-inactive-focus-label-text-color: #{map.get($fnl-palette, blue-dark)};
    --mat-tab-header-inactive-hover-label-text-color: #{map.get($fnl-palette, blue-dark)};
    --mat-tab-header-active-focus-label-text-color: #{map.get($fnl-palette, blue-light)};
    --mat-tab-header-active-hover-label-text-color: #{map.get($fnl-palette, blue-light)};
    --mat-tab-header-active-focus-indicator-color: #{map.get($fnl-palette, blue-light)};
    --mat-tab-header-active-hover-indicator-color: #{map.get($fnl-palette, blue-light)};

    .mat-mdc-tab-header {
      padding: 0 24px;
      margin-bottom: 24px;
    }

    .mat-mdc-tab-list {
      border-bottom: 1px solid #{map.get($fnl-palette, fish)};
    }

    .mat-mdc-tab-labels {
      gap: 32px;
    }

    .mdc-tab {
      @extend .text-h6;

      padding-right: 0;
      padding-left: 0;
    }
  }

}
