@use 'sass:map';
@use '../../../variables' as vars;

@mixin fnl-mdc-slide-toggle($fnl-palette) {

  .fnl-mdc-slide-toggle {

    // Shape
    &.mat-mdc-slide-toggle {
      .mdc-switch {
        &__track {
          width: 36px;
          height: 20px;
          border-radius: 10px;
        }

        &__handle {
          width: 16px;
          height: 16px;
          border-radius: 8px;
          left: 2px;
        }

        &__icons {
          svg {
            display: none;
          }
        }
      }

      .mdc-label {
        padding-left: 8px;
      }
    }

    // Colors
    &.mat-mdc-slide-toggle {
      &.mat-primary {
        .mdc-switch:enabled {
          .mdc-switch__track {
            &::before {
              background: map.get($fnl-palette, bg-tab);
            }

            &::after {
              background: #{rgba(map.get($fnl-palette, blue), 0.1)};
            }
          }
        }

        .mdc-switch.mdc-switch--selected:enabled {
          .mdc-switch__handle {
            &::after {
              background: map.get($fnl-palette, blue);
            }
          }
        }

        .mdc-switch.mdc-switch--unselected:enabled {
          .mdc-switch__handle {
            &::after {
              background: #{rgba(map.get($fnl-palette, grey), 0.3)};
            }
          }
        }
      }
    }

    // Typography
    &.mat-mdc-slide-toggle {

    }
  }

}