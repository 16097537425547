@use 'sass:map';

@mixin fnl-border-colors($fnl-palette) {
  .fnl-border-black {
    border-color: map.get($fnl-palette, black) !important;
  }

  .fnl-border-text {
    border-color: map.get($fnl-palette, text) !important;
  }

  .fnl-border-grey {
    border-color: map.get($fnl-palette, grey) !important;
  }

  .fnl-border-white {
    border-color: map.get($fnl-palette, white) !important;
  }

  .fnl-border-white-tr {
    border-color: map.get($fnl-palette, white-tr) !important;
  }

  .fnl-border-fish {
    border-color: map.get($fnl-palette, fish) !important;
  }

  .fnl-border-bg-tab {
    border-color: map.get($fnl-palette, bg-tab) !important;
  }

  .fnl-border-background {
    border-color: map.get($fnl-palette, background) !important;
  }

  .fnl-border-blue-dark {
    border-color: map.get($fnl-palette, blue-dark) !important;
  }

  .fnl-border-blue {
    border-color: map.get($fnl-palette, blue) !important;
  }

  .fnl-border-blue-light {
    border-color: map.get($fnl-palette, blue-light) !important;
  }

  .fnl-border-green {
    border-color: map.get($fnl-palette, green) !important;
  }

  .fnl-border-green-dark {
    border-color: map.get($fnl-palette, green-dark) !important;
  }

  .fnl-border-blue-text {
    border-color: map.get($fnl-palette, blue-text) !important;
  }

  .fnl-border-text-green {
    border-color: map.get($fnl-palette, text-green) !important;
  }

  .fnl-border-text-number {
    border-color: map.get($fnl-palette, text-number) !important;
  }

  .fnl-border-red {
    border-color: map.get($fnl-palette, red) !important;
  }

  .fnl-border-cool-gray1c {
    border-color: map.get($fnl-palette, cool-gray1c) !important;
  }

  .fnl-border-orange {
    border-color: map.get($fnl-palette, orange) !important;
  }

  .fnl-border-yellow {
    border-color: map.get($fnl-palette, yellow) !important;
  }

  .fnl-border-bg-3 {
    border-color: map.get($fnl-palette, bg-3) !important;
  }

  .fnl-border-bg-line {
    border-color: map.get($fnl-palette, bg-line) !important;
  }

  .fnl-border-tree-stick {
    border-color: map.get($fnl-palette, tree-stick) !important;
  }

  .fnl-border-special {
    border-color: map.get($fnl-palette, special) !important;
  }

  .fnl-border-button-disabled {
    border-color: map.get($fnl-palette, button-disabled) !important;
  }

  .fnl-border-new-random-color {
    border-color: map.get($fnl-palette, new-random-color) !important;
  }

  .fnl-border-tables-border {
    border-color: map.get($fnl-palette, tables-border) !important;
  }
}
