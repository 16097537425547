@use 'sass:map';

@mixin fnl-bg-colors($fnl-palette) {
  .fnl-bg-black {
    background-color: map.get($fnl-palette, black);
  }

  .fnl-bg-text {
    background-color: map.get($fnl-palette, text);
  }

  .fnl-bg-grey {
    background-color: map.get($fnl-palette, grey);
  }

  .fnl-bg-white {
    background-color: map.get($fnl-palette, white);
  }

  .fnl-bg-white-tr {
    background-color: map.get($fnl-palette, white-tr);
  }

  .fnl-bg-fish {
    background-color: map.get($fnl-palette, fish);
  }

  .fnl-bg-bg-tab {
    background-color: map.get($fnl-palette, bg-tab);
  }

  .fnl-bg-background {
    background-color: map.get($fnl-palette, background);
  }

  .fnl-bg-blue-dark {
    background-color: map.get($fnl-palette, blue-dark);
  }

  .fnl-bg-blue {
    background-color: map.get($fnl-palette, blue);
  }

  .fnl-bg-blue-light {
    background-color: map.get($fnl-palette, blue-light);
  }

  .fnl-bg-green {
    background-color: map.get($fnl-palette, green);
  }

  .fnl-bg-green-dark {
    background-color: map.get($fnl-palette, green-dark);
  }

  .fnl-bg-blue-text {
    background-color: map.get($fnl-palette, blue-text);
  }

  .fnl-bg-text-green {
    background-color: map.get($fnl-palette, text-green);
  }

  .fnl-bg-text-number {
    background-color: map.get($fnl-palette, text-number);
  }

  .fnl-bg-red {
    background-color: map.get($fnl-palette, red);
  }

  .fnl-bg-cool-gray1c {
    background-color: map.get($fnl-palette, cool-gray1c);
  }

  .fnl-bg-orange {
    background-color: map.get($fnl-palette, orange);
  }

  .fnl-bg-yellow {
    background-color: map.get($fnl-palette, yellow);
  }

  .fnl-bg-bg-3 {
    background-color: map.get($fnl-palette, bg-3);
  }

  .fnl-bg-bg-line {
    background-color: map.get($fnl-palette, bg-line);
  }

  .fnl-bg-tree-stick {
    background-color: map.get($fnl-palette, tree-stick);
  }

  .fnl-bg-special {
    background-color: map.get($fnl-palette, special);
  }

  .fnl-bg-button-disabled {
    background-color: map.get($fnl-palette, button-disabled);
  }

  .fnl-bg-new-random-color {
    background-color: map.get($fnl-palette, new-random-color);
  }

  .fnl-bg-tables-border {
    background-color: map.get($fnl-palette, tables-border);
  }
}
