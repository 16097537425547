@use "sass:map";

@mixin fnl-mdc-chips($fnl-palette) {

  .fnl-mdc-chip-set {

    .mat-mdc-standard-chip {
      --mdc-chip-label-text-font: var(--fnl-font-family-primary);

      --mdc-chip-elevated-container-color: #{rgba(map.get($fnl-palette, blue), .1)};
      --mdc-chip-focus-state-layer-color: transparent;

      .mdc-evolution-chip__icon--trailing {
        height: 16px;
        width: 16px;
        font-size: 16px;
      }
    }

    .mdc-evolution-chip__text-label {
      user-select: auto;
      cursor: default;
    }

    .mat-mdc-chip .mat-ripple.mat-mdc-chip-ripple {
      display: none;
    }

    .mat-mdc-chip-remove {
      opacity: 1 !important;
      color: var(--fnl-color-blue-light) !important;

      &:hover {
        color: var(--fnl-color-blue) !important;
      }

      &:active {
        color: var(--fnl-color-blue-dark) !important;
      }
    }

    &.fnl-mdc-chip-set--small {

      &.mdc-evolution-chip-set {

        .mdc-evolution-chip-set__chips {
          row-gap: 5px;
          margin-left: -4px;
        }

        .mdc-evolution-chip {
          margin: 0 0 0 4px;
        }
      }

      .mat-mdc-chip.mat-mdc-standard-chip {
        --mdc-chip-container-height: 24px;
      }

      .mat-mdc-standard-chip {
        --mdc-chip-container-shape-radius: 12px 12px 12px 12px;

        --mdc-chip-label-text-line-height: 16px;
        --mdc-chip-label-text-size: 13px;
        --mdc-chip-label-text-weight: 500;
      }
    }

    &.fnl-mdc-chip-set--medium {

      .mat-mdc-chip.mat-mdc-standard-chip {
        --mdc-chip-container-height: 40px;
      }

      .mat-mdc-standard-chip {
        --mdc-chip-container-shape-radius: 20px 20px 20px 20px;

        --mdc-chip-label-text-line-height: 20px;
        --mdc-chip-label-text-size: 15px;
        --mdc-chip-label-text-weight: 500;
      }
    }
  }
}
