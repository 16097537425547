@mixin form-field-shape {

  --mat-mdc-form-field-floating-label-scale: 0.85;
  --mat-form-field-container-text-font: var(--fnl-font-family-primary);
  --mat-form-field-container-text-line-height: 20px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-text-weight: 400;

  display: flex;

  &.fnl-form-field--small {
    @include form-field-size(32px, 20px, 14px);
    @include mat-spinner-size(16px);
  }

  &.fnl-form-field--medium {
    @include form-field-size(40px, 20px, 14px);
    @include mat-spinner-size(20px);
  }

  &.fnl-form-field--large {
    @include form-field-size(48px, 20px, 16px);
    @include mat-spinner-size(24px);
  }

  // Custom style for the form field container, where some icons are placed without using of matPrefix and matSuffix
  &.fnl-mdc-form-field-infix-flex {
    .mat-mdc-form-field-infix {
      display: flex;
      width: 100%;

      .mat-mdc-input-element {
        margin-right: 3px;
      }
    }
  }

  // Element in which the error message and hint are rendered
  &.fnl-form-field--render-subscript-wrapper {
    .mat-mdc-form-field-subscript-wrapper {
      .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
        padding: 0;
      }
    }
  }

  &:not(.fnl-form-field--render-subscript-wrapper) {
    .mat-mdc-form-field-subscript-wrapper {

      position: static;

      &.mat-mdc-form-field-bottom-align::before {
        display: none;
      }

      .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
        position: static;
        padding: 0;
      }

      mat-hint, mat-error {
        &.mat-mdc-form-field-bottom-align::before {
          display: none;
        }

        margin-top: 5px;
      }
    }
  }
}

@mixin form-field-size($height, $lineHeight, $fontSize) {

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: ($height - $lineHeight) / 2;
    padding-bottom: ($height - $lineHeight) / 2;
    min-height: $height;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    $offset: -$height / 2 - 6px;
    --mat-mdc-form-field-label-transform: translateY(#{$offset}) translateX(4px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: $height / 2;
  }

  .mdc-text-field--outlined {
    --mdc-outlined-text-field-outline-width: 1px;
    --mdc-outlined-text-field-focus-outline-width: 1px;
    --mdc-outlined-text-field-container-shape: #{$height / 2};

    padding-left: max(16px, calc(var(--mdc-outlined-text-field-container-shape)));
    padding-right: max(16px, calc(var(--mdc-outlined-text-field-container-shape)));
  }

  .mdc-text-field--outlined .mdc-floating-label {
    --mdc-outlined-text-field-label-text-font: var(--fnl-font-family-primary);
    --mdc-outlined-text-field-label-text-size: #{$fontSize};
    --mdc-outlined-text-field-label-text-weight: 400;

    font-size: var(--mdc-outlined-text-field-label-text-size) !important;

    left: 0;
  }

  .mat-mdc-form-field-icon-prefix > .mat-icon, .mat-mdc-form-field-icon-suffix > .mat-icon {
    padding: 0;
  }
}

@mixin mat-spinner-size($size) {
  .mat-mdc-progress-spinner {
    --mdc-circular-progress-size: $size !important;
    --mdc-circular-progress-active-indicator-width: $size !important;
    width: $size !important;
    height: $size !important;
  }
}
