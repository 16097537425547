@use "../../variables" as vars;

.fnl-carousel{
  .carousel-indicators{
    bottom: -50px;
  }
  
  .carousel-indicators button {
    height: 10px;
    width: 10px;
    background-color: vars.$color-fish;
    border-radius: 50%;
    opacity: 1;
    margin-right: 8px;
    margin-left: 8px;
    
    &.active {
      background-color: vars.$color-blue;
    }
  
  }
  
  .carousel-control-prev {
    width: 40px;
    height: 40px;
    top: calc(50% - 20px);
    left: 10px
  }
  
  .carousel-control-next {
    width: 40px;
    height: 40px;
    transform: rotate(180deg);
    top: calc(50% - 20px);
    right: 10px;
  }
}
