@use "sass:map";

@mixin fnl-mdc-radio-button--small {
}

@mixin fnl-mdc-radio-button--large {
}

@mixin fnl-mdc-radio-button($fnl-palette) {

  .mat-mdc-radio-button.fnl-mdc-radio-button {
    .mdc-radio {
      & + label {
        cursor: pointer;
      }

      &--disabled + label {
        cursor: default;
      }
    }

    &.mat-primary {
      --mdc-radio-unselected-icon-color: #{rgba(map.get($fnl-palette, blue), .4)};
    }
  }

  .mat-mdc-radio-button.fnl-mdc-radio-button--small {
    .mdc-radio {
      --mdc-radio-state-layer-size: 24px;
    }

    .mdc-form-field {
      --mdc-typography-body2-font-family: "Roboto";
      --mdc-typography-body2-font-size: 13px;
      --mdc-typography-body2-line-height: 16px;
      --mdc-typography-body2-font-weight: 400;
    }
  }

  .mat-mdc-radio-button.fnl-mdc-radio-button--large {
  }
}
