@use "sass:map";

@mixin fnl-mdc-button--small {
  --mdc-typography-button-font-size: 12px;
  --mdc-typography-button-line-height: 32px;

  height: 32px;
  border-radius: 16px;
}

@mixin fnl-mdc-button--large {
  height: 48px;
  border-radius: 24px;
}

@mixin fnl-mdc-button($fnl-palette) {

  .fnl-mdc-button {

    text-transform: uppercase;

    &.mdc-button {
      --mdc-typography-button-font-family: var(--fnl-font-family-primary);
      --mdc-typography-button-font-size: 14px;
      --mdc-typography-button-line-height: 40px;
      --mdc-typography-button-font-weight: 700;

      min-width: 42px;

      &.fnl-mdc-button--small {
        @include fnl-mdc-button--small;
        border-radius: 4px;
      }

      &.fnl-mdc-button--large {
        @include fnl-mdc-button--large;
        border-radius: 4px;
      }
    }

    &.mat-mdc-unelevated-button {
      height: 40px;
      border-radius: 20px;

      &.fnl-mdc-button--small {
        @include fnl-mdc-button--small;
      }

      &.fnl-mdc-button--large {
        @include fnl-mdc-button--large;
      }

      &.mat-primary, &.mat-accent, &.mat-warn {

        &.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
        &.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
          opacity: 0.75;
        }

        &:hover .mat-mdc-button-persistent-ripple::before {
          opacity: 1;
        }

        &:not(:disabled) {
          color: var(--fnl-color-white);
        }
      }

      &.mat-primary {
        --mat-mdc-button-persistent-ripple-color: #{map.get($fnl-palette, blue-dark)};
      }

      &.mat-accent {
        --mat-mdc-button-persistent-ripple-color: #{map.get($fnl-palette, color-accent-button-hover)};
      }

      &.mat-warn {
        --mat-mdc-button-persistent-ripple-color: #{adjust-color(map.get($fnl-palette, orange), $lightness: -10%)};
      }
    }

    &.mat-mdc-outlined-button {
      height: 40px;
      border-radius: 20px;
      border-width: 2px;

      &.fnl-mdc-button--small {
        @include fnl-mdc-button--small;
      }

      &.fnl-mdc-button--large {
        @include fnl-mdc-button--large;
      }

      &:not(:disabled) {
        border-color: var(--mdc-outlined-button-label-text-color);
      }

      &.mat-primary, &.mat-accent, &.mat-warn {
        &:hover {

          color: var(--fnl-color-white);

          .mat-mdc-button-persistent-ripple::before {
            opacity: 1;
          }
        }

        &.mat-primary {
          --mat-mdc-button-ripple-color: #{rgba(map.get($fnl-palette, blue-dark), .6)};
        }

        &.mat-accent {
          --mat-mdc-button-ripple-color: #{rgba(map.get($fnl-palette, color-accent-button-hover), .6)};
        }

        &.mat-warn {
          --mat-mdc-button-ripple-color: #{rgba(adjust-color(map.get($fnl-palette, orange), $lightness: -10%), .6)};
        }

        .mat-mdc-button-ripple, .mdc-button__ripple {
          border-width: 0;
        }
      }
    }

    &.mat-mdc-icon-button {

      &.mat-mdc-button-base {
        --mdc-icon-button-state-layer-size: 40px;
        width: var(--mdc-icon-button-state-layer-size);
        height: var(--mdc-icon-button-state-layer-size);
        padding: 10px;

        & > mat-icon {
          display: block;
          height: 20px;
          width: 20px;
          --mdc-icon-button-icon-size: 20px;

          svg {
            display: block;
          }
        }

        &.fnl-mdc-button--small {
          --mdc-icon-button-state-layer-size: 32px;
          padding: 8px;

          & > mat-icon {
            height: 16px;
            width: 16px;
            --mdc-icon-button-icon-size: 16px;

            svg {
              display: block;
            }
          }
        }

        &.fnl-mdc-button--large {
          --mdc-icon-button-state-layer-size: 48px;
          padding: 12px;

          & > mat-icon {
            height: 24px;
            width: 24px;
            --mdc-icon-button-icon-size: 24px;

            svg {
              display: block;
            }
          }
        }
      }

      .mat-mdc-button-touch-target {
        height: 40px;
        width: 40px;
      }

      &.fnl-mdc-button--small {
        .mat-mdc-button-touch-target {
          height: 32px;
          width: 32px;
        }
      }

      &.fnl-mdc-button--large {
        .mat-mdc-button-touch-target {
          height: 48px;
          width: 48px;
        }
      }
    }
  }
}
