@import 'fnl-ui';
@import 'common';

@import 'scss/common';

.cdk-overlay-container div.mat-mdc-select-panel,
.cdk-overlay-container div.mat-mdc-autocomplete-panel {
  max-height: calc(50vh - 32px) !important;
}

.prospecting-currency-select-panel {
  min-width: 312px;

  .mdc-list-item__primary-text {
    display: flex !important;
    align-items: center !important;
  }
}

.select-options-container {
  .mat-mdc-option .mdc-list-item__primary-text {
    width: 100% !important;
    margin-right: 0 !important;
  }
}
