@import '../variables';
$calendar-name: '.mat-calendar';

mat-datepicker-content.mat-datepicker-content {
  #{$calendar-name} {
    margin-top: 1px;

    &-body {
      &-selected {
        background-color: $color-blue-light;
      }
      &-today:not(#{$calendar-name}-body-selected) {
        border-color: $color-grey;
      }
    }
  }
}
