@import "../variables";

$circle-size: 8px;

@mixin circle($background-color: $color-blue, $top, $left) {
  width: $circle-size;
  height: $circle-size;

  border-radius: 50%;
  box-sizing: border-box;

  background-color: $background-color;
  content: "";

  position: absolute;
  top: $top;
  left: $left;
}

@mixin indent($top: 0, $left: 0%) {
  border: 1px solid #aec2d0;
  border-width: 0px 1px 0px 0px;
  box-sizing: border-box;

  width: 1px;
  height: 300%;

  position: absolute;
  left: $left;
}

@mixin stick($top, $left, $height, $border-width) {
  position: absolute;
  top: $top;
  left: $left;

  height: $height;

  border: 0 solid #aec2d0;
  border-width: $border-width;
  box-sizing: border-box;
}

.mat-accordion {
  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-expansion-panel {
    & .mat-expansion-panel-header.cdk-keyboard-focused,
    & .mat-expansion-panel-header.cdk-program-focused,
    &:not(.mat-expanded) .mat-expansion-panel-header:hover {
      &:not([aria-disabled="true"]) {
        background-color: rgba($color-black, 0.015);
      }
    }
  }
}

.mat-accordion.fnl-accordion {
  .mat-expansion-panel {
    color: $color-blue;
    border-top: 1px solid $color-fish;
    border-radius: 0 !important;
    background-color: transparent;
    box-shadow: none;
  }

  .mat-expansion-panel-header {
    max-height: 40px;
    padding: 0;
    font-weight: 400;

    .mat-icon {
      width: 14px;
      height: 14px;
      margin-left: auto;

      transition: transform $animation, color $animation;

      color: $color-blue;

      flex-shrink: 0;
      align-self: center;
    }
    &[aria-expanded="true"] {
      .mat-icon {
        transform: rotateZ(180deg);

        color: $color-blue-light;
      }
    }
  }
}

.fnl-tree-accordion {
  .mat-expansion-panel {
    border-top: 1px solid rgba($color-grey, 0.3);
    border-radius: 0 !important;
    background-color: transparent;
    box-shadow: none !important;

    .mat-expanded {
      .mat-icon {
        transform: rotateZ(180deg);
        color: $color-blue-light !important;
      }
    }

    .mat-expansion-panel-header {
      font-weight: 400;
    }
  }

  .mat-expansion-indicator {
    display: none;
  }

  .mat-content {
    height: 100%;
    padding: 0 8px;
  }

  .mat-expansion-panel-header {
    padding: 0;
    height: 40px !important;

    .mat-icon {
      width: 14px;
      height: 14px;

      margin-left: auto;
      transition: transform $animation, color $animation;
      color: $color-blue;
      flex-shrink: 0;
      align-self: center;
    }
  }

  .mat-expansion-panel-header-title {
    margin-right: 0px;
  }

  .tree-item-parent {
    $top: 50%;
    $left: calc($circle-size / 2);
    $height: 200%;
    $border-width: 0 0 0 1px;
    @include stick($top, $left, $height, $border-width);

    &::before {
      $background-color: $color-blue;
      $top: calc($circle-size / 2 * -1);
      $left: calc($circle-size / 2 * -1);
      @include circle($background-color, $top, $left);
    }
  }

  .tree-item-child-has-no-next-sibling,
  .tree-item-child-has-next-sibling {
    $top: calc((100% + 5.5px) * -1);
    $left: calc($circle-size / 2);
    $height: calc(100% + $circle-size * 2);
    $border-width: 0 0 1px 1px;
    @include stick($top, $left, $height, $border-width);

    width: 20px;

    &::before {
      $background-color: $color-special;
      $top: calc(100% - $circle-size / 2);
      $left: 16px;
      @include circle($background-color, $top, $left);
    }
  }

  .tree-item-child-has-next-sibling::after {
    $top: 50%;
    $left: -1px;
    $height: 200%;
    $border-width: 0 0 0 1px;
    @include stick($top, $left, $height, $border-width);

    content: "";
    margin-right: 4px;
  }

  .indent {
    @include indent(-120%, calc($circle-size / 2));
    top: -120%;
  }

  .description-indent {
    @include indent(0, 44px);
  }
}

.fnl-summary-accordion {
  border-bottom: 1px solid rgba($color-grey, 0.4);
  background: $color-background !important;
  box-shadow: none;
  &:last-of-type,
  &:first-of-type {
    border-radius: 0;
  }
  mat-expansion-panel-header {
    display: flex;

    height: auto;
    & div {
      display: flex;
      flex-direction: column;

      mat-panel-title > div {
        margin: 3px 16px 0 0;

        color: $color-grey;

        mat-icon {
          transition: transform $animation, color $animation;
          &.fnl-summary-accordion-open-icon_active {
            transform: rotate(180deg);

            color: $color-blue-light;
          }
        }
      }
      mat-panel-description {
        @extend .text-body2;

        color: $color-black;
      }
    }

    mat-panel-title {
      h2 {
        @extend .text-h3;
        font-size: 20px;

        padding-bottom: 4px;

        color: $color-black;
      }
    }
  }
}

.fnl-island-accordion {
  mat-expansion-panel.mat-expansion-panel {
    box-shadow: $shadow-block;

    &,
    &:last-of-type,
    &:first-of-type,
    &:not(.mat-expanded),
    &:not(.mat-expansion-panel-spacing) {
      border-radius: 16px;
    }
    &.mat-expansion-panel-spacing {
      margin: 0;
      margin-bottom: 4px;
    }
    &.mat-expanded {
      .mat-expansion-panel-header {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        background-color: $color-bg-tab;
      }
    }

    mat-panel-title {
      @extend .text-subtitle3;
    }

    &.mat-expanded {
      mat-panel-title > mat-icon {
        transform: rotate(180deg);

        color: $color-blue-light;
      }
    }

    &:not(.mat-expanded) {
      mat-panel-title > mat-icon {
        transform: none;
        color: $color-grey;
      }

      mat-expansion-panel-header {
        background-color: white !important;
      }
    }
  }

  mat-expansion-panel-header.mat-expansion-panel-header {
    height: auto !important;
    min-height: 44px;
    padding: 0;

    transition: background-color $animation, border-radius $animation;

    mat-panel-title,
    mat-panel-description {
      align-items: center;
    }
    mat-panel-title {
      margin-left: 8px;

      & > mat-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;

        transition: transform $animation, color $animation;

        color: $color-grey;
      }
    }
  }


}
