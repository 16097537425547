@use '@angular/material' as mat;
@use '../themes/mixins/fnl-theme' as fnl-theme;
@use '../colors/variables/fnl-palettes' as fnl-palettes;
@use '../colors/variables/material/light-theme-palettes' as light-theme-palettes;
@import '@angular/material/theming';
@import '../variables';
@import '../css-variables';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

$app-typography: mat.define-typography-config(
  $font-family: $font-family-primary,
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($app-typography);`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-legacy-component-typographies($app-typography);
@include mat.all-component-typographies($app-typography);
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.legacy-core();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$primary: mat.define-palette(light-theme-palettes.$fnl-light-primary-palette);
$accent: mat.define-palette(light-theme-palettes.$fnl-light-accent-palette);

// The warn palette is optional (defaults to red).
$warn: mat.define-palette(light-theme-palettes.$fnl-light-warn-palette);

$theme: mat.define-light-theme($primary, $accent, $warn);

// Include all theme styles for the components.
/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
@include mat.all-legacy-component-themes($theme);
@include mat.all-component-themes($theme);
@include fnl-theme.fnl-theme(fnl-palettes.$fnl-light-palette);

@import '../fonts';
@import '../typography';

// legacy-override styles of some default components
@import '../legacy-override/scrollbar';
@import '../legacy-override/button';
@import '../legacy-override/checkbox';
@import '../legacy-override/radio-button';
@import '../legacy-override/form-control';
@import '../legacy-override/field';
@import '../legacy-override/spinner';
@import '../legacy-override/tooltip';
@import '../legacy-override/button-toggle';
@import '../legacy-override/tabs';
@import '../legacy-override/table';
@import '../legacy-override/slide-toggle';
@import '../legacy-override/expansion-panel';
@import '../legacy-override/chip';
@import '../legacy-override/dialog';
@import '../legacy-override/calendar';
@import '../legacy-override/mat-form';
@import '../legacy-override/tree';
@import '../legacy-override/html-editor';

// override styles of some bootstrap components
@import '../legacy-override/bootstrap/carousel.scss';


// toastui-editor styles
@import '../toastui/toastui-editor-viewer';

// toastui-editor styles
// TODO move these styles to markdown-viewer component and set encapsulation: ViewEncapsulation.None, to make loading lazy
//  editor styles are around 173KB when viewer styles are around 12KB
//  make sure that viewer and editor use same styles to display Markdown.
//@import 'scss/toastui/toastui-editor-viewer';
// TODO move these styles to markdown-editor component and set encapsulation: ViewEncapsulation.None, to make loading lazy
//  editor styles are around 173KB when viewer styles are around 12KB
@import '../toastui/toastui-editor';
