@use "../variables" as variables;

// large desktop
@mixin screen-x-large {
  @media screen and (max-width: variables.$screen-x-large) {
    @content;
  }
}

// desktop
@mixin screen-large {
  @media screen and (max-width: variables.$screen-large) {
    @content;
  }
}

// tablet
@mixin screen-tablet {
  @media screen and (max-width: variables.$screen-tablet) {
    @content;
  }
}

// tablet portrait
@mixin screen-tablet-portrait {
  @media screen and (max-width: variables.$screen-tablet-portrait) {
    @content;
  }
}
