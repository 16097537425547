@use "sass:map";

@mixin fnl-mdc-autocomplete($fnl-palette) {

  .fnl-overlay-pane.cdk-overlay-pane {

    div.mat-mdc-autocomplete-panel {
      padding: 0;
      border-radius: 16px !important;

      .mat-mdc-option .mdc-list-item__primary-text {
        display: block;
        width: 100%;
      }
    }
  }
}
