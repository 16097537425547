@use "sass:map";
@use 'sass:color';

@mixin fnl-button($fnl-palette) {

  .fnl-button-as-link {

    padding: 0;
    margin: 0;

    background-color: transparent;
    color: map.get($fnl-palette, blue);
    box-shadow: none;
    border: none;
    cursor: pointer;

    text-decoration: underline;

    &:focus {
      outline: none;
      color: color.scale(map.get($fnl-palette, blue), $lightness: 20%);
    }

    &:hover {
      color: color.scale(map.get($fnl-palette, blue), $lightness: 40%);
    }

    &:active {
      color: color.scale(map.get($fnl-palette, blue), $lightness: 10%);
      outline: none;
      border-style: none;
    }

    &:disabled {
      color: rgba(map.get($fnl-palette, blue), 0.5);
      cursor: not-allowed;
    }
  }
}
