@import '../../assets/fonts/poppins/font-poppins';

@mixin font-face(
  $local-font-name1,
  $local-font-name2,
  $font-filename,
  $font-style,
  $font-weight
) {
  @font-face {
    font-family: 'Roboto';
    font-style: $font-style;
    font-weight: $font-weight;
    font-display: swap;
    src: local('#{$local-font-name1}'), local('#{$local-font-name2}'),
      url('../../assets/fonts/#{$font-filename}.woff2') format('woff2'),
      url('../../assets/fonts/#{$font-filename}.woff') format('woff'),
      url('../../assets/fonts/#{$font-filename}.ttf') format('truetype');
    text-rendering: optimizeLegibility;
  }
}

@include font-face(
  'Roboto Regular',
  'Roboto-Regular',
  'Roboto-Regular',
  normal,
  400
);
@include font-face(
  'Roboto Italic',
  'Roboto-Italic',
  'Roboto-Italic',
  italic,
  400
);

@include font-face(
  'Roboto Medium',
  'Roboto-Medium',
  'Roboto-Medium',
  normal,
  500
);
@include font-face(
  'Roboto Medium Italic',
  'Roboto-MediumItalic',
  'Roboto-MediumItalic',
  italic,
  500
);

@include font-face('Roboto Bold', 'Roboto-Bold', 'Roboto-Bold', normal, 700);
@include font-face(
  'Roboto Bold Italic',
  'Roboto-BoldItalic',
  'Roboto-BoldItalic',
  italic,
  700
);

@include font-face('Roboto Black', 'Roboto-Black', 'Roboto-Black', normal, 900);
@include font-face(
  'Roboto Black Italic',
  'Roboto-BlackItalic',
  'Roboto-BlackItalic',
  italic,
  900
);
