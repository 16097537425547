.ck-editor {
  .ck-editor__main {
    height: 560px;
  }

  & .ck-content {
    height: 100%;
  }
}

.ck-sticky-panel__content_sticky {
  z-index: 3 !important;
}