@import '../variables';
@import '../typography';

mat-button-toggle-group {
  &.mat-button-toggle-group-appearance-standard:not(.mat-button-toggle-group-default) {
    border-radius: 16px;
    border: none;
    box-shadow: inset 0 0 0 1px $color-blue;

    .mat-button-toggle-appearance-standard {
      color: $color-blue-dark;
      background-color: transparent;

      + .mat-button-toggle:not(:first-child) {
        border-left: 1px solid $color-blue;
      }

      .mat-button-toggle-label-content {
        @extend .text-button-small;
        line-height: 32px;
        padding: 0 16px;
      }

      &.mat-button-toggle-checked {
        background-color: $color-blue;
        color: $color-white;
      }

      &.mat-button-toggle-disabled {
        color: $color-grey;
      }
    }
  }
}

.fnl-button-toggle-group,
.fnl-button-toggle-radio-group {
  &.mat-button-toggle-group-appearance-standard:not(.mat-button-toggle-group-default) {
    border: 1px solid $color-grey !important;
    box-shadow: none !important;
    box-sizing: border-box;

    .mat-button-toggle-appearance-standard {
      flex: 1;
      color: $color-text !important;

      &.mat-button-toggle-checked {
        background-color: $color-green !important;
        color: white !important;

        .mat-icon {
          color: white !important;
        }
      }

      &.mat-button-toggle-disabled {
        color: $color-grey !important;
      }

      .mat-button-toggle-label-content {
        text-transform: none !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 400 !important;

        display: flex !important;
        padding: 0 !important;
      }

      .mat-button-toggle-button {
        padding: 0 16px;
      }

      + .mat-button-toggle:not(:first-child) {
        border-left: 1px solid $color-grey;
      }
    }
  }
}

.fnl-button-toggle-radio-group {
  $icon-size: 20px;
  $icon-margin: 8px;

  .mat-button-toggle {
    position: relative;

    &::before {
      content: "";
      background-image: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" fill="%2334668a" clip-rule="evenodd" d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM9.99987 18C5.59987 18 1.99987 14.4 1.99987 10C1.99987 5.6 5.59987 2 9.99987 2C14.3999 2 17.9999 5.6 17.9999 10C17.9999 14.4 14.3999 18 9.99987 18Z" /></svg>');

      display: block;
      position: absolute;
      top: calc(50% - ($icon-size / 2));
      left: $icon-margin;

      opacity: 0.4;

      height: $icon-size;
      width: $icon-size;
    }

    &.mat-button-toggle-checked {
      &::before {
        opacity: 1;

        background-image: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" fill="white" clip-rule="evenodd" d="M9.99987 5C7.19987 5 4.99987 7.2 4.99987 10C4.99987 12.8 7.19987 15 9.99987 15C12.7999 15 14.9999 12.8 14.9999 10C14.9999 7.2 12.7999 5 9.99987 5ZM10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM9.99987 18C5.59987 18 1.99987 14.4 1.99987 10C1.99987 5.6 5.59987 2 9.99987 2C14.3999 2 17.9999 5.6 17.9999 10C17.9999 14.4 14.3999 18 9.99987 18Z" /></svg>');
      }
    }

    .mat-button-toggle-label-content {
      margin-left: calc($icon-size);
    }
  }
}
