@import '../variables';
@import '../typography';
$mat-class: '.mat-form-field';

mat-form-field#{$mat-class} {
  font-size: 14px;
  line-height: 20px;

  display: block;

  width: 532px;

  color: $color-grey;
  &#{$mat-class}-appearance-outline {
    #{$mat-class} {
      &-flex {
        margin: 0;
        padding: 0 16px;
      }
      &-wrapper {
        margin-top: 0;
        padding-bottom: 24px;
      }
      &-outline {
        top: 0;

        color: transparent;
        &-thick {
          color: $color-blue-light;
        }
      }

      &-outline-start,
      &-outline-end,
      &-outline-gap {
        border-width: 1px;
        background-color: $color-white;
      }

      &-outline-start,
      &-outline-end {
        min-width: 20px;
      }

      &-outline-start {
        border-radius: 20px 0 0 20px;
      }
      &-outline-end {
        border-radius: 0 20px 20px 0;
      }

      &-prefix,
      &-suffix {
        top: auto;

        display: flex;

        width: 24px;
        height: 24px;

        justify-content: center;
        align-items: center;
        align-self: center;

        .mat-icon {
          &:not(.mat-icon-stroke) path {
            fill: $color-grey;
          }
          &.mat-icon-stroke path {
            stroke: $color-grey;
          }
        }
      }

      &-prefix {
        margin-right: 16px;
      }
      &-suffix {
        margin-left: 16px;
        .mat-icon-button {
          width: 36px;
          height: 36px;
          margin: -6px;
          &:hover {
            .mat-button-focus-overlay {
              opacity: 0.05;
            }
          }
          .mat-icon {
            height: 24px;
          }
        }
      }

      &-infix {
        font-weight: 500;

        margin: 0;
        padding: 10px 0;

        border-top: none;
      }

      &-subscript-wrapper {
        font-size: 14px;
        line-height: 20px;

        position: inherit;
        top: auto;

        margin-top: 8px;
        padding: 0;
        .mat-error {
          color: $color-red;
        }
      }
    }

    &#{$mat-class}-invalid#{$mat-class}-invalid:not(#{$mat-class}-no-validation) {
      #{$mat-class} {
        &-outline-thick {
          color: $color-red;
        }
        &-outline-start,
        &-outline-end,
        &-outline-gap {
          border-width: 2px;
        }
      }
      .mat-input-element {
        caret-color: $color-red;
        &::placeholder {
          color: rgba($color-red, 0.5);
        }
      }
    }

    &.mat-focused {
      #{$mat-class}-outline-thick,
      &.mat-accent #{$mat-class}-outline-thick {
        color: $color-blue-light;
      }

      &.mat-warn {
        #{$mat-class} {
          &-outline-thick {
            color: $color-orange;
          }
          &-outline-start,
          &-outline-end,
          &-outline-gap {
            border-width: 2px;
          }
        }
        .mat-input-element {
          caret-color: $color-orange;
          &::placeholder {
            color: rgba($color-orange, 0.5);
          }
        }
      }
    }
  }

  .mat-input-element {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      font-size: inherit;

      transition: background-color 5000s ease-in-out 0s;

      -webkit-text-fill-color: $color-grey;
    }
  }

  textarea.mat-input-element {
    height: 140px;

    resize: none;
  }

  &.fnl-form-field {

    &.fnl-form-field--small {

      // fields height
      .mat-form-field-infix {
        padding: 6px 0;
      }
    }

    .mat-form-field-infix {
      font-weight: 400;
      color: $color-text;
    }

    &.fnl-form-field--no-indent {

      .mat-form-field-wrapper, .mat-form-field-subscript-wrapper {
        margin: 0;
        padding: 0;
      }
    }

    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      border-color: $color-grey;
    }

    &.mat-form-field-disabled .mat-form-field-outline-start,
    &.mat-form-field-disabled .mat-form-field-outline-gap,
    &.mat-form-field-disabled .mat-form-field-outline-end {
      border-color: $color-fish;
    }

    #{$mat-class}-outline-thick,
    &.mat-warn {
      #{$mat-class} {
        &-outline-start,
        &-outline-end,
        &-outline-gap {
          border-color: rgba($color-blue-light, .6);
        }
      }
    }

    &#{$mat-class}-invalid#{$mat-class}-invalid:not(#{$mat-class}-no-validation) {
      #{$mat-class} {
        &-outline-start,
        &-outline-end,
        &-outline-gap {
          border-color: $color-red;
        }
      }
    }

    &.mat-focused {
      #{$mat-class}-outline-thick,

      &.mat-warn {
        #{$mat-class} {
          &-outline-start,
          &-outline-end,
          &-outline-gap {
            border-color: rgba($color-blue-light, .6);
          }
        }
      }
    }

    &.fnl-search-field {
      .mat-form-field-prefix {
        margin-top: 2px;
        margin-right: 9px;
        height: 15px;
        width: 15px;
      }
    }

    // Arrow in the select element
    .mat-select-arrow {
      border-left: 0 !important;
      margin: 0 4px !important;
      width: 6px !important;
      height: 6px !important;
      border-top: 2px solid $color-blue !important;
      border-right: 2px solid $color-blue !important;
      transform: rotate(135deg) !important;
      opacity: 1;
    }

    .mat-select-disabled {
      .mat-select-arrow {
        border-top-color: $color-tree-stick !important;
        border-right-color: $color-tree-stick !important;
      }
    }

    // Arrow position in the select element
    &.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
      transform: translateY(-14%) !important;
    }
  }
}

.cdk-overlay-pane {
  // dropdown in select
  .mat-select-panel {
    border-radius: 16px;
  }

  .mat-option {
    height: 40px !important;
  }

  .mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
    background: $color-blue;
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $color-text;
  }
}

mat-checkbox {
  .mat-checkbox {
    &-inner-container {
      width: 18px;
      height: 18px;
      margin-right: 11px;
      margin-left: 3px;
    }
  }

  .mat-checkbox-frame {
    border-color: $color-bg-line;
    border-radius: 2px;
  }
  .mat-checkbox-layout {
    .mat-checkbox-label {
      @extend .text-body1;

      color: $color-blue-dark;
    }
  }
  &.mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: $color-blue;
  }
}

mat-radio-button {
  .mat-radio-container {
    margin-right: 10px;
    margin-left: 10px;
  }
  .mat-radio-label-content {
    @extend .text-body1;

    padding: 0;

    color: $color-text;
  }
}
