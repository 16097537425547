@import "../typography";

.mat-tooltip.mat-tooltip {
  font-size: 11px;
  line-height: 12px;

  margin: 0 0 6px;
  padding: 2px 7px;

  text-transform: lowercase;

  color: $color-white;
  border-radius: 8px;
  background: rgba($color-blue-light, 0.8);

  &-closure {
    margin-bottom: 0;
  }

  &-info {
    margin-bottom: 4px;
  }

  &-po1 {
    @extend .text-caption;
    text-transform: none;

    padding: 8px;

    background: $color-blue-dark;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
  }
}
