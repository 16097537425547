@use "sass:map";

@mixin form-field-color($fnl-palette) {

  --mdc-outlined-text-field-focus-outline-color: #{rgba(map.get($fnl-palette, blue-light), .6)};
  --mdc-outlined-text-field-focus-label-text-color: #{rgba(map.get($fnl-palette, blue-light), .6)};
  --mdc-outlined-text-field-outline-color: #{map.get($fnl-palette, grey)};
  --mdc-outlined-text-field-disabled-outline-color: #{rgba(map.get($fnl-palette, grey), .5)};
  --mdc-outlined-text-field-hover-outline-color: #{rgba(map.get($fnl-palette, blue-light), .6)};

  .mdc-text-field--outlined {

    .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        background-color: rgba(map.get($fnl-palette, white), .5);
      }
    }

    &:not(.mdc-text-field--disabled) {
      .mdc-notched-outline {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          background-color: map.get($fnl-palette, white);
        }
      }
    }

    &:has(input[readonly]) {
      .mdc-notched-outline {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: map.get($fnl-palette, background);
          background-color: map.get($fnl-palette, background);
        }
      }
    }

    .mat-mdc-form-field-infix {
      // this is in order to make the text above the outline background
      z-index: 1;
    }
  }

  .mat-mdc-form-field-hint {
    color: lighten(map.get($fnl-palette, black), 50%);
  }

  &.fnl-form-field--dark-theme {

    --mdc-outlined-text-field-focus-outline-color: var(--fnl-color-white);

    --mat-select-enabled-trigger-text-color: var(--fnl-color-white);
    --mat-select-disabled-trigger-text-color: var(--fnl-color-grey);
    --mat-select-placeholder-text-color: var(--fnl-color-white-tr);
    --mat-select-enabled-arrow-color: var(--fnl-color-white-tr);

    .mdc-text-field--outlined {

      .mdc-notched-outline {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          background-color: rgba(map.get($fnl-palette, blue-dark), .5);
        }
      }

      &:not(.mdc-text-field--disabled) {
        .mdc-notched-outline {
          .mdc-notched-outline__leading,
          .mdc-notched-outline__notch,
          .mdc-notched-outline__trailing {
            background-color: map.get($fnl-palette, blue-dark);
          }
        }
      }
    }
  }
}
