@import '../variables';

.mat-radio-button {
  &.mat-primary {
    .mat-radio-outer-circle {
      border-color: rgba($color-blue, 0.4);
    }

    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border-color: $color-blue;
      }
      .mat-radio-persistent-ripple {
        background-color: $color-blue;
      }
    }
    &:active {
      .mat-radio-persistent-ripple {
        background-color: $color-blue;
      }
    }

    .mat-radio-inner-circle,
    .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple) {
      background-color: $color-blue;
    }
  }
}
