@use 'fnl-variables' as variables;
@use 'fnl-mixins' as mixins;

html {
  scroll-behavior: smooth;
  scroll-snap-stop: always;
}

html,
body {
  min-width: 1000px;
  height: 100%;
}

body {
  font-family: variables.$font-family-primary;
  font-size: variables.$font-size;

  margin: 0;

  color: variables.$color-text;
  background-color: variables.$color-background;

  /* better text quality on Mac OS */
  /* may decrease performance */

  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

* {
  &:focus {
    outline: none;
  }
}

.cdk-overlay-container * {

  overscroll-behavior: none;

}

input,
textarea,
button {
  font-family: variables.$font-family-primary;
}

textarea {
  color: #90a4ae;
}

.global-wrapper {
  max-width: variables.$container-width;
  margin: 0 auto;

  @include mixins.screen-tablet {
    padding-right: 20px;
    padding-left: 20px;
  }
}
