@import '../variables';
@import '../typography';

$border-radius-tabs: 20px;

.text-tab {
  font-size: 15px;
  font-weight: 500;
  line-height: 40px;

  letter-spacing: 0.15px;
}

.fnl-tab-group .mat-tab-header {
  .mat-tab-label {
    height: 40px;

    opacity: 1;
    border-radius: $border-radius-tabs;

    &.mat-tab-label-active {
      color: $color-white;
    }
    &.mat-tab-disabled {
      color: $color-grey;
    }
  }
}

.fnl-tab-group .mat-tab-header,
.mat-tab-nav-bar.mat-tab-header {
  border-bottom: 0;
  border-radius: $border-radius-tabs;
  background: $color-bg-tab;
  box-shadow: $shadow-block;

  .mat-tab-label,
  .mat-tab-link {
    height: 40px;

    opacity: 1;
    border-radius: $border-radius-tabs;

    &.mat-tab-link-active {
      color: $color-white;
    }
    &.mat-tab-disabled {
      color: $color-grey;
    }

    .mat-ripple-element {
      background-color: rgba($color-blue-dark, 0.1);
    }
  }

  .mat-tab-label,
  .mat-tab-link {
    @extend .text-tab;

    transition: 0.2s background-color, 0.3s color;

    color: $color-blue-dark;

    &:not(.mat-tab-link-active):not(.mat-tab-disabled):hover {
      background-color: rgba($color-blue-light, 0.1);
    }
  }

  .mat-ink-bar {
    z-index: -1;

    height: 100%;

    border-radius: $border-radius-tabs;
  }
}

.mat-tab-nav-bar.mat-tab-header {
  button:first-of-type {
    border-radius: 20px 0 0 20px;
  }

  button:last-of-type {
    border-radius: 0 20px 20px 0;
  }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $color-blue-light;
}
