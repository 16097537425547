@import "../variables";
@import "../mixins/flexbox.scss";
@import "../typography";

.fnl-tree {
  outline: 1px solid $color-fish;
  border-radius: 10px;

  background-color: inherit;

  .mat-button-wrapper {
    @include flexBox($justify-content: center, $align-items: center);
  }

  .fnl-tree-node--active {
    color: white;
    background-color: $color-green;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: -12px;
      clip-path: polygon(0 0, 12px 50%, 0 100%);
      background-color: $color-green;
      height: 100%;
      width: 12px;
    }

    &:last-child {
      border-radius: 0 0 0 10px;
    }

    &:first-child {
      border-radius: 10px 0 0 0;
    }
  }

  .mat-tree-node {
    @extend .text-body2;
    cursor: default;
    min-height: 36px;

    .mat-icon {
      color: $color-grey;
    }

    &.fnl-tree-parent {
      @extend .text-body1-md;

      button {
        padding: 0;
        margin: 8px 8px 8px 4px;
        height: 24px;
        width: 24px;
      }

      &.fnl-tree-top-level-parent {
        background-color: #f6f6f6cc;
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
        text-transform: uppercase;

         button {
           margin: 8px;
         }
      }
    }

    .fnl-tree-node--expanded {
      color: $color-blue-light;
      transform: rotate(180deg);
    }

    &:not(:last-child) {
      border-bottom: 1px solid $color-fish;
    }
  }
}
