@mixin flexBox(
  $justify-content: flex-start,
  $align-items: flex-start,
  $flex-direction: row
) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
  flex-direction: $flex-direction;
}
