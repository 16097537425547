@import '../variables';

@mixin custom-scrollbar($isLight: false) {
  $bg: transparent;
  $thumb: rgba($color-blue, 0.8);

  @if $isLight == true {
    $thumb: rgba($color-blue, 0.2);
  }

  // for firefox
  scrollbar-width: thin;
  scrollbar-color: $thumb $bg;

  // for chrome
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: $bg;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $thumb;
    border-radius: 3px;
  }
}
