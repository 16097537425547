@use "sass:map";

@use "form-field-shape" as form-field-shape;
@use "form-field-color" as form-field-color;


@mixin fnl-mdc-form-field($fnl-palette) {

  .fnl-form-field {

    &.mat-mdc-form-field.mat-form-field-appearance-outline {

      @include form-field-shape.form-field-shape;
      @include form-field-color.form-field-color($fnl-palette);

    }
  }
}
