@import '../variables';
@import '../typography';

$sort-prefix: '.mat-sort-header';

.mat-table {
  table-layout: fixed;
  border-collapse: collapse;

  #{$sort-prefix} {
    &-container {
      position: relative;

      display: inline-flex;

      &::after {
        position: absolute;
        top: 50%;
        right: -17px;

        display: block;

        width: 12px;
        height: 13px;

        content: '';
        transform: translateY(-50%);

        background-color: $color-grey;

        mask: url('../../../assets/images/icons/arrow-sort-down.svg') no-repeat center
          center;
      }
    }

    &-arrow {
      display: none;
    }

    &-content {
      display: block;

      text-align: inherit;

      color: inherit;
    }

    &-sorted {
      &::after {
        background-color: $color-blue-light;
      }
    }

    &.cdk-program-focused #{$sort-prefix}-container {
      border-bottom-width: 0;
    }
  }

  .mat-header-row {
    height: 40px;
  }

  .mat-row {
    height: 32px;

    &:hover {
      background-color: rgba($color-background, 0.5);
    }
  }

  .mat-header-cell,
  .mat-cell {
    @extend .text-th;

    box-sizing: border-box;
    padding: 0;

    vertical-align: middle;

    border-bottom: 1px solid $color-fish;
  }

  .mat-header-cell {
    font-weight: normal;

    color: $color-black;
    border-bottom-color: $color-tables-border;
    background: $color-background;

    &[aria-sort='ascending'],
    &[aria-sort='descending'] {
      color: $color-blue-light;
    }

    &[aria-sort='ascending'] {
      #{$sort-prefix}-container::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  .mat-cell {
    color: $color-text;
  }
}
