@use 'sass:map';

@mixin fnl-text-colors($fnl-palette) {
  .fnl-text-black {
    color: map.get($fnl-palette, black);
  }

  .fnl-text-text {
    color: map.get($fnl-palette, text);
  }

  .fnl-text-grey {
    color: map.get($fnl-palette, grey);
  }

  .fnl-text-white {
    color: map.get($fnl-palette, white);
  }

  .fnl-text-white-tr {
    color: map.get($fnl-palette, white-tr);
  }

  .fnl-text-fish {
    color: map.get($fnl-palette, fish);
  }

  .fnl-text-bg-tab {
    color: map.get($fnl-palette, bg-tab);
  }

  .fnl-text-background {
    color: map.get($fnl-palette, background);
  }

  .fnl-text-blue-dark {
    color: map.get($fnl-palette, blue-dark);
  }

  .fnl-text-blue {
    color: map.get($fnl-palette, blue);
  }

  .fnl-text-blue-light {
    color: map.get($fnl-palette, blue-light);
  }

  .fnl-text-green {
    color: map.get($fnl-palette, green);
  }

  .fnl-text-green-dark {
    color: map.get($fnl-palette, green-dark);
  }

  .fnl-text-blue-text {
    color: map.get($fnl-palette, blue-text);
  }

  .fnl-text-text-green {
    color: map.get($fnl-palette, text-green);
  }

  .fnl-text-text-number {
    color: map.get($fnl-palette, text-number);
  }

  .fnl-text-red {
    color: map.get($fnl-palette, red);
  }

  .fnl-text-cool-gray1c {
    color: map.get($fnl-palette, cool-gray1c);
  }

  .fnl-text-orange {
    color: map.get($fnl-palette, orange);
  }

  .fnl-text-yellow {
    color: map.get($fnl-palette, yellow);
  }

  .fnl-text-bg-3 {
    color: map.get($fnl-palette, bg-3);
  }

  .fnl-text-bg-line {
    color: map.get($fnl-palette, bg-line);
  }

  .fnl-text-tree-stick {
    color: map.get($fnl-palette, tree-stick);
  }

  .fnl-text-special {
    color: map.get($fnl-palette, special);
  }

  .fnl-text-button-disabled {
    color: map.get($fnl-palette, button-disabled);
  }

  .fnl-text-new-random-color {
    color: map.get($fnl-palette, new-random-color);
  }

  .fnl-text-tables-border {
    color: map.get($fnl-palette, tables-border);
  }

  .fnl-svg-icon-grey {
    fill: map.get($fnl-palette, grey);
  }

  .fnl-svg-icon-blue {
    fill: map.get($fnl-palette, blue);
  }
}
