@use './functions/container.utils' as container;

$font-family-primary: 'Roboto', sans-serif;
$font-family-secondary: 'Poppins', sans-serif;
$font-size: 14px;

$container-x-large-width: 1560px;
$container-x-large-indent: 0;

$container-width: 1320px;
$container-indent: 20px; // TODO: this is not the final indent

$container-tablet-width: 1112px;
$container-tablet-indent: 20px;
$container-tablet-portrait-width: 768px;

// breakpoints
$screen-x-large: container.calculateWidth(
  $container-x-large-width,
  $container-x-large-indent
);
$screen-large: container.calculateWidth(
  $container-width + 10,
  $container-indent
);
$screen-tablet: container.calculateWidth(
  $container-tablet-width,
  $container-tablet-indent
);
$screen-tablet-portrait: container.calculateWidth(
  $container-tablet-portrait-width,
  $container-tablet-indent
);

// new colors
$color-black: #000000;
$color-text: rgba($color-black, 0.87);
$color-grey: #abacac;
$color-white: #ffffff;
$color-white-tr: rgba($color-white, 0.4);
$color-fish: #e0e0e0;
$color-bg-tab: #ebebeb;
$color-background: #f6f6f6;
$color-blue-dark: #134d5f;
$color-blue: #34668a;
$color-blue-light: #3e99ac;
$color-green: #aac638;
$color-green-dark: #1c4b35;
$color-blue-text: $color-blue-light;
$color-text-green: #338c47;
$color-text-number: #6ba72f;
$color-red: #f2453d;
$color-cool-gray1c: $color-fish;
$color-orange: #ff7b59;
$color-yellow: #f2c94c;
$color-bg-3: #f3f4f7;

$color-bg-line: #a8bccb;
$color-tree-stick: #aec2d0;
$color-special: #b9c6d8;
$color-button-disabled: #d6d7d9;

$color-new-random-color: #ebf0f3;
$color-tables-border: #cdcdcd;
$color-accent-button-hover: #889e2d;

// old colors - remove after updating
$color-black-tr: rgba($color-black, 0.87);

// shadow

$shadow-block: 0px 2px 2px rgba(0, 0, 0, 0.1);
$shadow-block-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));
$shadow-popup: 0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12);
$shadow-popup-filter: drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.2))
  drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.12));

$animation: var(--fin-animation-speed, 0.2s) ease;

$overlay-z-index: 1000;
