.mat-basic-chip {
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;

  position: relative;

  display: inline-flex;

  min-width: 42px;
  height: 1px;
  min-height: 16px;
  padding: 0 8px;

  cursor: default;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateZ(0);

  color: $color-white;
  border: none;
  border-radius: 12px;
  background-color: $color-blue;

  align-items: center;
  -webkit-tap-highlight-color: transparent;
  justify-content: center;
  appearance: none;
  .mat-chip-ripple {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow: hidden;

    pointer-events: none;

    border-radius: inherit;
  }
  .mat-standard-chip::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';
    transition: opacity 200ms cubic-bezier(0.35, 0, 0.25, 1);
    pointer-events: none;

    opacity: 0;
    border-radius: inherit;
    background-color: $color-white;
  }
  &:not(:disabled):not(.mat-chip-disabled) {
    &:active {
      box-shadow: 0 2px 3px -3px rgba(0, 0, 0, 0.2),
        0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
    }
    &:focus {
      outline: none;
      &:after {
        opacity: 0.16;
      }
    }
    &:hover:after {
      opacity: 0.12;
    }
  }
}

.fnl-chips {
  .mat-chip.mat-standard-chip {
    font-size: 15px;
    line-height: 20px;
    height: 40px;
    border-radius: 20px;
    padding: 0 8px 0 16px;
    background-color: #34668A1A;

    &:not(:disabled):not(.mat-chip-disabled) {
      &:active {
        box-shadow: none!important;
      }
      &:focus {
        outline: none;
        &:after {
          opacity: 0;
        }
      }
      &:hover:after {
        opacity: 0;
      }
    }
  }
}
