@import '../variables';

.mat-slide-toggle {
  .mat-slide-toggle-bar {
    background: rgba($color-grey, 0.5);
  }

  .mat-slide-toggle-thumb {
    background-color: $color-grey;
    box-shadow: none;
  }

  .mat-ripple-element {
    background-color: rgba($color-blue-dark, 0.1);
  }

  &.mat-primary {
    &.mat-checked {
      .mat-slide-toggle-bar {
        background: $color-bg-line;
      }

      .mat-slide-toggle-thumb {
        background-color: $color-blue;
      }
    }
  }

  &.mat-accent {
    &.mat-checked {
      .mat-slide-toggle-bar {
        background: rgba($color-green, 0.5);
      }

      .mat-slide-toggle-thumb {
        background-color: $color-green;
      }
    }
  }

  &.mat-slide-toggle_alternative {
    line-height: 20px;

    height: 20px;

    .mat-slide-toggle-bar {
      width: 36px;
      height: 20px;

      border-radius: 10px;
      background: $color-bg-tab;
    }

    .mat-slide-toggle-thumb-container {
      top: 2px;
      left: 2px;

      width: 16px;
      height: 16px;
    }

    .mat-slide-toggle-thumb {
      width: 16px;
      height: 16px;

      background-color: rgba($color-grey, 0.3);
    }

    .mat-slide-toggle-ripple {
      top: calc(50% - 16px);
      left: calc(50% - 16px);

      width: 32px;
      height: 32px;

      border-radius: 50%;
    }

    &.mat-primary {
      &.mat-checked {
        .mat-slide-toggle-bar {
          background: rgba($color-blue, 0.1);
        }

        .mat-slide-toggle-thumb {
          background-color: $color-blue;
        }
      }
    }
  }
}
